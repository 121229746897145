<template>
  <t-modal
    :name="id"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="!isLoading"
    :clickToClose="!isLoading"
    ref="popup"
  >
    <section class="flex justify-center items-center mt-8 mb-2 text-gray-500">
      <i class="text-4xl text-green-600 " :class="icon"></i>
    </section>

    <section class="px-4 text-2xl font-extrabold text-center">
      {{ title }}
    </section>
    <section class="px-4 font-bold text-center">
      {{ subtitle }}
    </section>
    <section class="px-4 py-5 mb-10">
      <TRichSelect
        v-model="pushOptions"
        :options="mediumOptions"
        :close-on-select="true"
        :placeholder="'Select mediums(s)'"
        hideSearchBox
        multiple
      />
    </section>
    <section
      class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
    >
      <AppButton
        :variant="cancelButtonVariant"
        :class="`w-1/2`"
        @click="executeAction('cancel')"
      >
        {{ cancelButtonText }}
      </AppButton>

      <AppButton
        :variant="notifyButtonVariant"
        :class="`w-1/2`"
        :isLoading="isLoading"
        @click="executeAction('notify')"
      >
        {{ notifyButtonText }}
      </AppButton>
    </section>
  </t-modal>
</template>

<script>
export default {
  name: 'NotifyModal',
  components: {},

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: [String, Number],
      required: false,
      default: 'fas fa-bell',
    },
    title: {
      type: [String, Number],
      required: false,
      default: 'Are you sure?',
    },
    subtitle: {
      type: [String, Number],
      required: false,
    },
    cancelButtonText: {
      type: [String, Number],
      required: false,
      default: 'Nevermind',
    },
    cancelButtonVariant: {
      type: String,
      required: false,
      default: 'primary',
    },
    notifyButtonText: {
      type: [String, Number],
      required: false,
      default: 'Notify',
    },
    notifyButtonVariant: {
      type: String,
      required: false,
      default: 'success',
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    pushOptions: [],
    queryStrings: '',
    mediumOptions: [
      {
        text: 'Push',
        value: 'push',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'SMS',
        value: 'sms',
      },
    ],
  }),

  methods: {
    executeAction(type) {
      if (type === 'notify' && this.isLoading === false) {
        if (this.pushOptions.length > 0) {
          this.$emit('setQueryString', this.getQueryString(this.pushOptions))
          this.$emit('notify')
          this.pushOptions = []
        } else {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'No medium Selected',
            },
            3000
          )
        }
      }

      if (type === 'cancel' && this.isLoading === false) {
        this.$refs.popup.hide()
        this.$emit('cancel')
        this.pushOptions = []
      }
    },
    getQueryString(options) {
      const queryString = options.join('&')
      return '?' + queryString
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
